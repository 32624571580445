import * as React from 'react';
import {Helmet} from 'react-helmet';

import Layout from '@components/layouts/layout';
import Navigation from '@components/nav-footer/navigation';
import Footer from '@components/nav-footer/footer';

import PublishModule from '@components/publish/publish-module';

import {Container, Row, Col} from 'react-bootstrap';

const Publish = () => {
  return (
    <Layout>
      <Helmet>
        <title>Publikacja – Atakama Studio</title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>

      <Navigation />

      <div className="publish">
        <Container className="publish__container">
          <Row>
            <Col>
              <h1>Publikacja</h1>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PublishModule password="atakama2022" albusDumbledoreSiteId="atakama-studio" buildTime={5} />
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </Layout>
  );
};

export default Publish;
